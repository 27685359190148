@use './variables' as v

.MuiChip-root.chip-style
    font-family: v.$font-content
    margin: 5px
    border: solid 1px #cacfca
    background: v.$colorbg-lightmode
    color: v.$font-lightmode
    font-weight: 500
    // padding: 0px 10px
    svg
        font-size: 16px
        padding: 5px 6px
        margin-left: 3px
        height: 25px
        width: 25px
        border-radius: 50%
        // margin-right: 1px
        background: #fff !important
    &.html svg
        color: #e34c26!important
    &.css svg
        color: #2965f1
    &.js svg
        color: #f0db4f
    &.angular svg
        color: #d4173b
    &.jQuery svg
        color: #0769ad
    &.SAP svg
        color: #507d2a
    &.react svg
        color: #61dbfb
    &.firebase svg
        color: #F5820D
    &.net svg
        color: #512bd4

.tech-stack-item
    &.html svg
        color: #e34c26!important
    &.css svg
        color: #2965f1
    &.js svg
        color: #f0db4f
    &.angular svg
        color: #d4173b
    &.jQuery svg
        color: #0769ad
    &.SAP svg
        color: #507d2a
    &.react svg
        color: #61dbfb
    &.firebase svg
        color: #F5820D
    &.net svg
        color: #512bd4

.darkTheme 
    .ant-divider
        border-color: v.$color-container-darkmode


.fullPage
    height: 100vh
    width: 100%
    // position: sticky
    // top: 0
    z-index: 14
    transition: all 0.3s ease

.rounded-corner-background
    background: white
    border: solid 1px #f1f1f1
    box-shadow: 10px 10px 20px #d9dadc, -10px -10px 20px #ffffff
    border-radius: 10px
.darkTheme 
    .rounded-corner-background
        background: v.$color-container-darkmode!important
        box-shadow: none!important
        border: none!important
        text-shadow: none!important