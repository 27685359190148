@use './../../../assets/variables' as v

.login-page-container
    margin: auto
    display: flex
    flex-direction: column
    justify-content: center
    .welcome_image
        max-height: 300px
        @media screen and (max-width: 768px)
            max-width: 80%
    .login-content
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        background: white
        gap: 2rem
        padding: 100px 0px 70px 0px
    .loggedin-content
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        .profile-info
            padding: 100px 20px 80px 20px
            display: flex
            flex-direction: row
            justify-content: center
            gap: 15%
            align-items: center
            background: white
            width: 100%
            height: 50vh
            flex-wrap: wrap
            border-bottom: solid 2px v.$color-primary
            @media screen and (max-width: 768px) 
                height: 70vh
            position: relative
            h1
                font-family: v.$font-headings
                font-weight: 600
                letter-spacing: -2px
                font-size: 70px
                text-align: left
                margin: 0
                span
                    color: v.$color-primary
                @media screen and (max-width: 768px)
                    font-size: 5vh
                    margin-top: 10vh
        .blogs-container
            max-width: 1400px
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            padding: 30px
            margin: 40px auto
            .blogs-title
                font-family: v.$font-headings
                font-weight: 600
                letter-spacing: -2px
                font-size: 2rem
                text-align: left
                width: 100%
        &.loggedin-content
            .user-profile-picture
                border-radius: 50%
        .photos-category-creation-container
            display: flex
            flex-direction: row
            justify-content: center
            justify-content: center
            width: 100%
            max-width: 1400px
            padding: 40px 20px 40px 20px
            gap: 2rem
            @media screen and (max-width: 768px)
                flex-direction: column
            .existing-categories-container
                padding: 30px
                flex-grow: 1
                display: flex
                flex-direction: column
                gap: 3vh
                @media screen and (max-width: 768px) 
                    padding: 18px
            .existing-categories-container
                .existing-category-header
                    display: flex
                    flex-direction: row
                    flex-wrap: wrap
                    justify-content: space-between
                    align-items: center
                    .photos-category-creation-title
                        font-family: Roboto Flex
                        font-weight: 600
                        letter-spacing: -1px
                        margin: 0
                        font-size: 2rem
                        @media screen and (max-width: 768px) 
                            font-size: 1.5rem
                    .category-actions
                        transition: filter 0.3s ease-in-out
                        display: flex
                        gap: 10px
                        .add-category-action, .delete-category-action, .edit-category-action, .hide-category-action
                            font-size: 30px
                            width: 40px
                            height: 40px
                            padding: 10px
                            border-radius: 50%
                            border: solid 2px v.$color-dark-primary
                            transition: all 0.3s ease-in-out
                            cursor: pointer
                            user-select: none
                            @media screen and (max-width: 768px) 
                                height: 30px
                                width: 30px
                                padding: 6px
                            &:hover
                                transform: scale(1.1)
                            &.active
                                color: white
                                border: none
                        .edit-category-action
                            padding: 8px
                            @media screen and (max-width: 768px) 
                                padding: 5px
                            &.active
                                background: v.$cyan-process!important
                                border: solid 2px v.$cyan-process
                        .delete-category-action
                            &.active
                                background: v.$fire-opal!important
                                border: solid 2px v.$fire-opal
                        .hide-category-action
                            &.active
                                background: v.$star-command-blue!important
                                border: solid 2px v.$star-command-blue
                        .add-category-action
                            &.active
                                background: v.$mountain-meadow!important
                                border: solid 2px v.$mountain-meadow

                .existing-categories
                    display: flex
                    flex-direction: row
                    flex-wrap: wrap
                    gap: 35px
                    justify-content: center
                    margin-bottom: 3vh
                    .existing-category
                        height: 324px
                        width: 190px
                        position: relative
                        border: solid 1px #f1f1f1
                        border-radius: 12px
                        box-shadow: 5px 5px 5px #d9dadc, -5px -5px 5px #ffffff
                        transition: all 0.3s ease-in-out
                        aspect-ratio: 1/1.7
                        @media screen and (max-width: 768px) 
                            flex: auto
                            height: auto
                        .category-name
                            position: absolute
                            font-family: v.$font-content
                            text-transform: uppercase
                            background: #ffffff77
                            backdrop-filter: blur(10px)
                            padding: 8px 10px
                            text-align: center
                            letter-spacing: 5px
                            font-size: 12px
                            bottom: -1px
                            width: 100%
                            left: 0
                            border-radius: 0px 0px 12px 12px

                        img
                            object-fit: cover
                            max-width: 100%
                            height: 100%
                            border-radius: 12px
                            transition: all 1s ease
                        &.inactive 
                            box-shadow: none
                            img
                                opacity: 0.7
                                filter: brightness(0.7)
                        .edit-category-btn, .delete-category-btn, .hide-category-btn
                            font-size: 24px
                            color: white
                            width: 36px
                            height: 36px
                            position: absolute
                            right: -10px
                            top: -10px
                            padding: 11px
                            border-radius: 50%
                            text-align: center
                            cursor: pointer
                        .edit-category-btn
                            background: v.$cyan-process!important
                        .delete-category-btn
                            background: v.$fire-opal!important
                        .hide-category-btn
                            background: #00000077!important
                            bottom: 2px
                            height: 30px
                            width: 30px
                            padding: 8px
                            right: 2px
                            top: unset

    .login-button, .logout-button
        padding: 10px 20px
        border-radius: 5px
        background-color: #1abc9c
        color: white
        font-family: v.$font-content
        outline: none
        border: none
        height: auto
        svg
            margin-right: 8px
    .logout-button
        border-radius: 0
        right: 0
        position: absolute
        bottom: 0px

// Blur category
.filter-blur-5
    filter: blur(5px)
// Edit/Delete confirmation popover
.delete-category-popup
    font-family: v.$font-content
    .ant-popover-inner
        border-radius: 6px
    .ant-popover-buttons
        .ant-btn-dangerous
            background: v.$fire-opal
            color: white

// New category creation modal
.new-category-modal
    .ant-modal-close
        top: -5px
    .ant-modal-content
        border-radius: 12px
    .ant-modal-header
        border-radius: 12px 12px 0px 0px
    .ant-modal-title
        font-family: v.$font-headings
        font-size: 24px
        font-weight: 600
        letter-spacing: -1px
    .new-category-container
        // padding: 30px
        flex-grow: 1
        display: flex
        flex-direction: column
        gap: 3vh
    .photos-category-creation-title, .category-label, .category-thumbnail-title, .category-images-title
        font-family: Roboto Flex
        font-weight: 500
        letter-spacing: -1px
        margin-bottom: 10px
        font-size: 20px
    .category-creation-form
        display: flex
        flex-direction: column
        gap: 30px
    .new-category-container
        min-width: 40%
        .error-category-exists
            font-family: v.$font-content
            font-size: 14px
            color: v.$fire-opal
            display: inline-block
            margin-top: 5px
            margin-left: 5px
        .add-category-btn
            padding: 10px 20px
            border-radius: 5px
            background-color: #1abc9c
            color: white
            font-family: v.$font-content
            outline: none
            border: none
            width: 100px
            &[disabled]
                background: #ccc

.darkTheme
    .login-page-container
        .login-content
            background: v.$colorbg-darkmode
        .profile-info
            background: v.$colorbg-darkmode
            h1
                color: v.$font-darkmode
