@use './assets/variables' as v 

*
  box-sizing: border-box
  margin: 0
  padding: 0
  /* transition-property: background-color, color */
  /* transition: all 0.3s ease!important */

* svg, * path
  background: transparent!important
  // color: unset!important

.main-content-container, .content-container
  transition: all 0.3s ease

.main-content-container.darkTheme, .main-content-container.darkTheme #butter
  background-color: v.$colorbg-darkmode
  color: #aaa
.main-content-container.lightTheme, .main-content-container.lightTheme #butter
  background-color: v.$colorbg-lightmode
  color: #000

/* #root 
 overflow: hidden
 */

a:hover
  text-decoration: none

.h-0
  height: 0px!important
  overflow: hidden

.ant-form-item-label > label
  color: #d5d5d5!important

.loading-screen
  background-color: v.$colorbg-lightmode
  .color-container
    height: 70vh
    background-color: v.$fire-opal
    font-family: v.$font-headings
    font-size: 8vmax
    font-weight: 300
    color: white