@import "./assets/common"

body
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  width: 100%!important

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

body::-webkit-scrollbar
  width: 1px

body::-webkit-scrollbar-track
  background-color: #e4e4e4
  border-radius: 100px

body::-webkit-scrollbar-thumb
  background-color: #aaa
  border-radius: 100px
