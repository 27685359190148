@use './../../../assets/variables' as v

.gallery
  margin-top: 70px
  transition: all 0.3s ease
  display: flex
  flex-direction: row
  width: 100%
  border-top: solid 3px v.$color-light-primary
  position: relative
  @media screen and (max-width: 768px)
    flex-direction: column
  .edit-photogallery-icon
    position: absolute
    right: 5vw
    padding: 15px
    height: 50px
    width: 50px
    background-color: v.$color-dark-primary!important
    color: white
    border-radius: 50%
    top: 20px
    cursor: pointer
    z-index: 111
    transition: all 0.3s ease
    @media screen and (max-width: 768px)
      height: 40px
      width: 40px
      padding: 10px
      top: 25px  
    &.active
      background: v.$cyan-process!important
    &:hover
      transform: scale(1.1)
      background-color: v.$color-container-darkmode
  .category-info-container
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 30px
    width: 30%
    border-right: solid 3px v.$color-light-primary
    @media screen and (max-width: 768px)
      border-right: none
      border-bottom: solid 3px v.$color-light-primary
      width: auto
      img
        width: 100%

    .category-title
      font-family: v.$font-headings
      text-transform: capitalize
      font-weight: 500
      letter-spacing: -1px
      // background: v.$colorbg-lightmode
      color: v.$font-lightmode
      padding: 10px 0px
      // border-radius: 10px
      text-align: center
      width: min-content
      border: solid 1px #eee
      @media screen and (max-width: 768px)
        padding: 0
      &.name
        margin-top: 20px
        // width: 100%
        text-align: left
    .category-feature-photo
      max-height: 500px
      max-width: 400px
      border-radius: 12px
    .category-actions
      width: 100%
      .ant-upload
        @media screen and (max-width: 768px)
          display: block
          width: 100%
          button
            width: 100%
      .add-photos-btn
        font-size: 18px
        // width: 40px
        height: 50px
        border-radius: 10px
        transition: all 0.3s ease-in-out
        cursor: pointer
        user-select: none
        display: flex
        justify-content: center
        gap: 7px
        line-height: 10px
        align-items: center
        font-family: v.$font-content
        font-weight: 900
        &:hover
          transform: scale(1.1)
          &.active
            color: white
            border: none

// .gallery *
  // transition: all 0.3s ease
.row-gallery-container
  padding: 100px 30px 30px 30px
  overflow: auto
  position: relative
  &.category-edit-mode
    justify-content: left
    max-height: calc( 100vh - 135px )
    @media screen and (max-width: 768px)
      max-height: unset
      padding: 30px
  @media screen and (max-width: 786px)
    overflow: hidden
    max-height: unset

  .row-gallery-title
    font-weight: 500
    letter-spacing: -1px
    text-align: center
    width: min-content
    position: absolute
    left: 100px
    top: 20px
    font-size: 25px
    font-family: v.$font-content
    color: white
    padding: 10px 20px
    background-color: transparent
    color: black
    // left: 45px
    transition: all 0.5s ease
    text-transform: capitalize
    overflow: hidden
    z-index: 11
    outline: none
    @media screen and (max-width: 768px)
      left: 18px
    &.scrolled
      position: fixed
      transform: rotate(90deg)
      transform-origin: left
      color: white
      background: black
      transition: all 0.5s ease
      left: 20px
      top: 80px
      @media screen and (max-width: 768px)
        font-size: 16px

  .row-gallery
    display: -ms-flexbox
    /* IE10 */
    display: flex
    flex-direction: row
    justify-content: center
    -ms-flex-wrap: wrap
    gap: 20px
    /* IE10 */
    flex-wrap: wrap

.disclaimer
  padding: 20px
  background: rgba(0, 0, 0, 0.8)
  color: sandybrown
  width: 90vw
  margin: auto
  max-height: 300px

.columnGallery25
  -ms-flex: 25%
  /* IE10 */
  flex: 25%
  max-width: 25%
  padding: 0 4px

.columnGallery50
  -ms-flex: 50%
  /* IE10 */
  flex: 50%
  max-width: 50%
  padding: 0 4px

.columnGallery25
  -ms-flex: 75%
  /* IE10 */
  flex: 75%
  max-width: 75%
  padding: 0 4px

.columnGallery img
  margin-top: 8px
  vertical-align: middle
  width: 100%

.row-gallery-container::-webkit-scrollbar
  width: 5px
.row-gallery-container::-webkit-scrollbar-track
  background-color: #e4e4e4
  border-radius: 100px

.row-gallery-container::-webkit-scrollbar-thumb
  background-color: #aaa
  border-radius: 100px

.gallery-title
  font-family: 'ROBOTO SLAB'

.photo-card-container
  border-radius: 10px
  position: relative
  box-shadow: 10px 10px 20px #d9dadc, -10px -10px 20px #ffffff
  height: fit-content
  &:hover
    .category-edit-actions
      .hide-image-btn, .make-category-thumb
        opacity: 1
  .category-edit-actions
    .hide-image-btn, .make-category-thumb, .feature-photo
      position: absolute
      bottom: 5px
      font-size: 18px
      z-index: 111
      background: #00000077!important
      padding: 8px
      height: 36px
      width: 36px
      border-radius: 50%
      color: white
      cursor: pointer
      opacity: 0
      backdrop-filter: blur(10px)
    .hide-image-btn
      right: 5px
      &.btn-show
        opacity: 1
    .make-category-thumb
      left: 5px
    .feature-photo
      color: gold
      right: 5px
      top: 5px
      bottom: unset
      opacity: 1

.darkTheme
  .gallery
    border-color: v.$color-dark-primary
    .row-gallery-container
      .row-gallery-title
        background: v.$color-container-darkmode
        color: v.$font-darkmode
        border: none

    .category-info-container
      border-color: v.$color-dark-primary
      .category-title
        color: v.$font-darkmode
        border: none
        border-radius: 10px
        &.name
          background: v.$color-container-darkmode

  .photo-card-container
    box-shadow: none

/* Responsive layout - makes a two column-layout instead of four columns */

@media screen and (max-width: 960px)
  .columnGallery
    -ms-flex: 33%
    flex: 33%
    max-width: 33%

@media screen and (max-width: 800px)
  .columnGallery
    -ms-flex: 50%
    flex: 50%
    max-width: 50%

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */

@media screen and (max-width: 600px)
  .columnGallery
    -ms-flex: 100%
    flex: 100%
    max-width: 100%
