@use "./../../../assets/variables" as v

// .navbar.newblogs
//   .nav-links, i, .navbar-logo
//     color: white
//     .darkModeToggle
//       border-color: white
//       .ant-switch-handle::before
//         background: white
.blog-container
    margin: 0
    transition: all 0.3s ease
    display: flex
    justify-content: center
    .no-content-container
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        gap: 3vh
        padding: 2rem 5vw
        img.no-content-image
            max-height: 300px
            max-width: 90%
        span.no-content-text
            font-family: v.$font-content
            font-weight: 600
            font-size: 1.5vmax
            text-transform: uppercase
            word-spacing: 10px
            letter-spacing: -1px

    .top-container
        border-bottom: 5px solid v.$color-primary
        width: 100%
        display: flex
        justify-content: center
        align-items: center
        position: relative
        padding-top: 70px
        background: white
        // margin-top: -35px
        height: 70vh
        @media screen and (min-width: "1440px")
            height: 50vh
        .disclaimer-text
            position: absolute
            bottom: -1px
            right: 0px
            font-family: v.$font-content
            font-weight: 400
            color: #ffffffbb
            letter-spacing: 1px
            writing-mode: vertical-lr
            transform: rotate(180deg)
            background: v.$color-primary
            text-transform: uppercase
            padding: 18px 14px
        .img-container
            height: 100%
            max-width: 1400px
            width: 100%
            background-size: cover
            background-position: center -100px
            position: relative
            justify-content: space-around
            align-items: center
            display: flex
            flex-direction: row
            &::after
                content: ""
                background: url("../../../assets/images/svg_writer_dark.svg") right no-repeat
                background-size: 75%
                background-position: 50%
                width: 45%
                height: 100%
                display: block
                margin: auto
            .text-blog-headline, .type, .OR
                font-family: v.$font-headings
                font-size: 7vmax
                letter-spacing: -0.3vw
                font-weight: 600
                line-height: 0.75
                word-break: nowrap
                display: block
                margin: auto
                // text-shadow: 10px 10px 20px #0f0f0f, -10px -10px 20px #151515
                .asterisk
                    color: v.$color-primary
                .OR
                    left: 50%
                    transform: translate(-50%, 0%)
                    height: fit-content
                .type
                    left: unset
                    right: 0px
                    text-align: right

    .blog-posts-container
        max-width: 1400px
        margin: auto

    .blog-posts-container
        flex-direction: row
        align-items: flex-start
        justify-content: center
        flex-wrap: wrap
        gap: 1.5vmax
        width: 100%
        padding: 40px 10px
        // margin-top: 80px
        .blog-link-skeleton
            width: 100%
            padding: 20px
            display: flex
            align-items: center
            border-radius: 10px
            background: #fff
            border: solid 1px #f1f1f1
            .blog-content-skeleton
                width: 75%
            .blog-link-title-skeleton
                width: 50%
            .blog-link-image-skeleton
                width: 20%
                margin: 20px auto
                height: 130px
                border-radius: 10px
        .blog-link
            background: #fff
            padding: 20px
            position: relative
            transition: all 0.3s ease
            border-radius: 10px
            display: flex
            flex: auto
            align-items: center
            border: solid 1px #f1f1f1
            box-shadow: 10px 10px 20px #d9dadc, -10px -10px 20px #ffffff
            .blog-content
                height: 100%
                width: 100%
            .blog-link-title
                font-family: v.$font-headings
                transition: all 0.3s ease
                font-weight: 600
                letter-spacing: -1px
                display: flex
                justify-content: space-between
                font-size: 1.8rem
                // color: #f1f1f1
                @media screen and (max-width: 768px)
                    font-size: 24px
                    // flex-direction: column
            .blog-delete-button
                outline: none
                border: none
                background: none
                .blog-delete-icon
                    background: orangered
            .blog-edit-icon, .blog-delete-icon
                height: 36px
                width: 36px
                padding: 10px
                border-radius: 50%
                background: black!important
                color: white
                transition: all 0.3s ease
                margin-left: 10px
                &:hover
                    transform: scale(1.05)
                    background: #1abc9c!important
            .blog-delete-icon
                &:hover
                    background: orangered!important

            p.blog-post-summary
                font-family: v.$font-content
                font-size: 16px
                word-spacing: 0px
                // max-width: 450px
            .blog-link-image
                float: right
                max-height: 150px
                flex-grow: 1
        .blog-post-date
            font-family: v.$font-content
            font-size: 14px
            font-weight: 400
            color: #999
            margin: 0
            // margin-top: 10px
            margin-bottom: 0px
            letter-spacing: 0px
            text-align: right
            min-width: 150px
        .post-author-container
            display: flex
            margin-top: 24px
            flex-direction: row
            justify-content: flex-end
            align-items: center
            gap: 10px
            .username
                color: #999
                font-family: v.$font-headings
                font-weight: 600
                letter-spacing: -0.3px
                display: none
            .user-profile-picture
                display: flex
                height: 36px
                width: 36px
                overflow: hidden
                border-radius: 50%
                img
                    object-fit: contain

.pencil
    background: linear-gradient(90deg, #ccc, #fff, #ccc)
    height: 500px
    width: 30px
    position: absolute
    top: 50%
    right: 14%
    transform: translate(-50%, -50%)
    transition: all 1s ease
    border-radius: 10px 10px 10px 10px
    box-shadow: 10px 10px 20px #0797c1, -10px -10px 20px #ff665100
    &:hover
        transform: translate(-50%, -50%) scale(1.05) rotate(15deg)
    .brand-logo
        font-family: 'Roboto Flex'
        position: absolute
        transform: rotate(-90deg)
        left: -22px
        top: 100px
        /* text-transform: capitalize */
        font-weight: 600
        color: #999
        white-space: nowrap
    .pencil__top
        display: block
        height: 50px
        width: 100%
        position: relative
        background: v.$light-green
        border: solid 1px #aaaaaa33
        border-radius: 10px 10px 0px 0px
        border-bottom: solid 3px v.$light-green
    .pencil__bottom
        position: absolute
        bottom: -40px
        width: 5px
        height: 0
        border-left: 15px solid transparent
        border-right: 15px solid transparent
        border-top: 50px solid #efefef
        border-radius: 0px 0px 0px 0px
        &::after
            content: ""
            position: absolute
            color: white
            height: 10px
            width: 5px
            background: white
            border-radius: 50%
            left: -2.6px
            top: -11px
    .pencil__tip
        width: 0.2px
        height: 0.2px
        background: linear-gradient(90deg, #ccc, v.$fire-opal, #f1f1f1)
        position: absolute
        top: 0px
        left: 0
        border-radius: 0% 0px 50% 50%

.darkTheme
    .top-container
        background-color: v.$colorbg-darkmode
    .text-blog-headline
        color: v.$font-darkmode
    .blog-posts-container
        .blog-link, .blog-link-skeleton
            background: v.$color-container-darkmode
            box-shadow: none
            border: none
        .blog-link-title
            color: v.$colorbg-lightmode
        .blog-content
            color: v.$font-darkmode
@media screen and ( min-width: 1400px )
    .blog-container
        .top-container
            .img-container
                .text-blog-headline, .type
                    font-size: 5vmax!important
                    letter-spacing: -0.2vw
                    max-width: 45%

@media screen and ( max-width: 768px )
    .blog-container
        .top-container
            margin-top: 0
            .img-container
                flex-direction: column
                justify-content: space-evenly
                &::after
                    height: 50%
                    width: 60%
                    background-size: 100%
                    flex-wrap: wrap
    .ant-divider
        margin: 10px 0px
        .blog-posts-container
        .blog-link
            .blog-content
                width: 100%
            .blog-link-title
                font-size: 28px
            p
                font-size: 15px
            .blog-link-image
                max-height: 100px
