@font-face
    font-family: Amstelvar
    src: url(Amstelvar.ttf) 
    font-weight: normal
    font-style: normal

/* SASS HEX */
$mountain-meadow: #1abc9cff
$star-command-blue: #2176aeff
$cultured: #f0f3f5ff
$fire-opal: #f15946ff
$black: #000000ff

/* SASS HSL */
$mountain-meadow: hsla(168, 76%, 42%, 1)
$star-command-blue: hsla(204, 68%, 41%, 1)
$cultured: hsla(204, 20%, 95%, 1)
$fire-opal: hsla(7, 86%, 61%, 1)
$black: hsla(0, 0%, 0%, 1)

/* SASS RGB */
$mountain-meadow: rgba(26, 188, 156, 1)
$star-command-blue: rgba(33, 118, 174, 1)
$cultured: rgba(240, 243, 245, 1)
$fire-opal: rgba(241, 89, 70, 1)
$black: rgba(0, 0, 0, 1)

/* SASS Gradient */
$gradient-top: linear-gradient(0deg, #1abc9cff, #2176aeff, #f0f3f5ff, #f15946ff, #000000ff)
$gradient-right: linear-gradient(90deg, #1abc9cff, #2176aeff, #f0f3f5ff, #f15946ff, #000000ff)
$gradient-bottom: linear-gradient(180deg, #1abc9cff, #2176aeff, #f0f3f5ff, #f15946ff, #000000ff)
$gradient-left: linear-gradient(270deg, #1abc9cff, #2176aeff, #f0f3f5ff, #f15946ff, #000000ff)
$gradient-top-right: linear-gradient(45deg, #1abc9cff, #2176aeff, #f0f3f5ff, #f15946ff, #000000ff)
$gradient-bottom-right: linear-gradient(135deg, #1abc9cff, #2176aeff, #f0f3f5ff, #f15946ff, #000000ff)
$gradient-top-left: linear-gradient(225deg, #1abc9cff, #2176aeff, #f0f3f5ff, #f15946ff, #000000ff)
$gradient-bottom-left: linear-gradient(315deg, #1abc9cff, #2176aeff, #f0f3f5ff, #f15946ff, #000000ff)
$gradient-radial: radial-gradient(#1abc9cff, #2176aeff, #f0f3f5ff, #f15946ff, #000000ff)

/* SCSS HEX */
$fire-opal: #f15946ff
$light-green: #acf39dff
$cyan-process: #08b2e3ff
$magnolia: #efe9f4ff
$rich-black-fogra-39: #02020bff

/* SCSS HSL */
$fire-opal: hsla(7, 86%, 61%, 1)
$light-green: hsla(110, 78%, 78%, 1)
$cyan-process: hsla(193, 93%, 46%, 1)
$magnolia: hsla(273, 33%, 94%, 1)
$rich-black-fogra-39: hsla(240, 69%, 3%, 1)

/* SCSS RGB */
$fire-opal: rgba(241, 89, 70, 1)
$light-green: rgba(172, 243, 157, 1)
$cyan-process: rgba(8, 178, 227, 1)
$magnolia: rgba(239, 233, 244, 1)
$rich-black-fogra-39: rgba(2, 2, 11, 1)

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #f15946ff, #acf39dff, #08b2e3ff, #efe9f4ff, #02020bff)
$gradient-right: linear-gradient(90deg, #f15946ff, #acf39dff, #08b2e3ff, #efe9f4ff, #02020bff)
$gradient-bottom: linear-gradient(180deg, #f15946ff, #acf39dff, #08b2e3ff, #efe9f4ff, #02020bff)
$gradient-left: linear-gradient(270deg, #f15946ff, #acf39dff, #08b2e3ff, #efe9f4ff, #02020bff)
$gradient-top-right: linear-gradient(45deg, #f15946ff, #acf39dff, #08b2e3ff, #efe9f4ff, #02020bff)
$gradient-bottom-right: linear-gradient(135deg, #f15946ff, #acf39dff, #08b2e3ff, #efe9f4ff, #02020bff)
$gradient-top-left: linear-gradient(225deg, #f15946ff, #acf39dff, #08b2e3ff, #efe9f4ff, #02020bff)
$gradient-bottom-left: linear-gradient(315deg, #f15946ff, #acf39dff, #08b2e3ff, #efe9f4ff, #02020bff)
$gradient-radial: radial-gradient(#f15946ff, #acf39dff, #08b2e3ff, #efe9f4ff, #02020bff)


$font-headings: 'Roboto Flex', sans-serif
$font-content: 'Manrope', sans-serif
$font-lightmode: #121212
$font-darkmode: #e1e3e5
$color-primary: #1abc9c
$color-secondary: #8DD9C4
$color-dark-primary: #1f1f1f
$color-light-primary: #e1e3e5
$colorbg-darkmode: #020402
$colorbg-lightmode: #f1f3f5
$color-container-darkmode: #1f1f1f