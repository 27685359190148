@use './../assets/variables' as v

.bg-black
    background: black!important

// .darkTheme
//     .navbar *
//         color: v.$font-darkmode
a
    color: unset
nav, nav a
    transition: all ease 0.3s
.navbar
    height: 70px
    display: flex
    justify-content: center
    align-items: baseline
    font-size: 1.2rem
    position: fixed
    top: 0
    left: 0
    right: 0
    z-index: 999
    padding: 0
    color: v.$font-lightmode
    // background: transparent
    // backdrop-filter: blur(0px)
    transition: all 0.3s ease
    // background-size: 0%
    a
    .navbar-container
        // background: transparent
    .nav-menu, .nav-links, .nav-item, i, li, a,

.compact.navbar
    .nav-menu, .nav-links, .nav-item, i, li
        // transition: background-color 0s

.compact.navbar
.compact.navbar, .compact.navbar .navbar-container, .compact.navbar .nav-links
    // font-weight: 500
    color: white
.compact.navbar
    height: 42px
    background: rgba(0, 0, 0, 1)!important
    // backdrop-filter: blur(10px)
    // border-bottom: solid 2px v.$color-primary

.compact.navbar .navbar-logo
    font-size: 1rem
    color: v.$font-darkmode
    img
        height: 35px
// .compact .nav-item, .compact .nav-links
//     height: 38px  

.compact .nav-links
    padding: 0.25rem 0.5rem
    font-size: 1rem

.navbar-container
    display: flex
    justify-content: space-between
    align-items: baseline
    width: 90%
    max-width: 1400px

.navbar-logo
    justify-self: start
    margin-left: 20px
    cursor: pointer
    text-decoration: none
    font-size: 1.8rem
    display: flex
    align-items: center
    background: transparent

.navbar-logo-text img
    height: 50px
    color: #fff
    transition: all 0.3s ease

// @keyframes focusInOut
//     0%
//         -webkit-filter: blur(0px)
//         -moz-filter: blur(0px)
//         -o-filter: blur(0px)
//         -ms-filter: blur(0px)
//         filter: blur(0px)

//     50%
//         -webkit-filter: blur(3px)
//         -moz-filter: blur(3px)
//         -o-filter: blur(3px)
//         -ms-filter: blur(3px)
//         filter: blur(3px)

//     100%
//         -webkit-filter: blur(0px)
//         -moz-filter: blur(0px)
//         -o-filter: blur(0px)
//         -ms-filter: blur(0px)
//         filter: blur(0px)

// .navbar-logo-text:hover
//     animation-name: focusInOut
//     animation-duration: 1.5s
//     animation-timing-function: cubic-bezier(0.035, 0.967, 0.023, 0.999, 0.03, 0.685, 0.01)

.compact .navbar-logo-text
    color: white
    background: transparent

.fa-typo3
    margin-left: 0.5rem
    font-size: 1.8rem

.nav-menu
    display: grid
    grid-template-columns: repeat(6, auto)
    grid-gap: 10px
    list-style: none
    text-align: center
    justify-content: end
    margin-right: 2rem
    margin-bottom: 0
    .ant-switch-checked
        background-color: inherit

.lightTheme .compact
    .nav-item .nav-links
        color: white

.nav-links
    font-family: v.$font-content
    font-weight: 400
    display: flex
    align-items: center
    text-decoration: none
    padding: 0.75rem 1rem
    height: 100%
    border-top: solid 3px transparent
    transition: all 0.3s ease

.nav-links:hover
    border-top: solid 3px
    & > span
        transform: scale(1.1)
        transition: transform 0.3s ease

.nav-links.active, .compact .nav-links.active
    // color: v.$color-primary!important
    border-top: solid 3px
    // background: #ffffff88
    // border-radius: 0px 0px 10px 10px
    // backdrop-filter: blur(25px)
.fa-bars, .fa-times
    transition: all 0.3s ease

.nav-links-mobile
    display: none

.login-dropdown
    padding-top: 0px
    .ant-dropdown-menu-item
        text-align: center
        line-height: 16px
        .ant-dropdown-menu-title-content
            display: flex
            align-content: center
            gap: 8px
        svg
            font-size: 16px
            margin-top: 1px
.navbar
    .nav-profile-picture
        width: 36px
        height: 36px
        border-radius: 50%
    &.compact
        .nav-profile-picture
            height: 28px
            width: 28px
            margin-top: 2.5px

.darkModeToggle
    // background: #000
    overflow: hidden
    // color: white
    box-sizing: content-box
    box-shadow: none!important
    border: 1.5px solid v.$font-lightmode
    .ant-switch-handle
        background: transparent
    svg
        color: black
.lightTheme
    .compact .darkModeToggle
        border-color: white
        position: relative
        top: 1px
        .ant-switch-handle::before
            background: white
        svg
            color: white!important
    .darkModeToggle
        .ant-switch-handle::before
            background: #000
        .navbar svg
            color: black

.menu-icon
    display: none

.darkTheme
    .darkModeToggle
        border-color: white!important
    .navbar svg
        color: white!important
    .nav-menu .nav-item a, .navbar-logo
        color: white
    .photoGalleryTitle
        color: white!important
    .photoGalleryTitle.scrolled
        color: white
        background: #232323

.compact .color-div
    height: inherit
    width: 50px
    background: v.$color-primary
    position: absolute
    right: 0
    z-index: -1

@media screen and (max-width: 768px)
    .mobile-menu-svg
        height: 20%
        width: 100%
        overflow: hidden
        padding: 40px
        transition: all 0.3s ease
        background: url("../assets/images/undraw_refreshing_beverage.svg") no-repeat
        background-size: contain
        background-position: 50%
        position: relative
        top: 50px
    .lightTheme
        .navbar
            .nav-menu.activeMenu
                background: v.$colorbg-lightmode
                .nav-links
                    color: v.$font-lightmode
            // height: 50px
            .navbar-logo.mobilemenu-active, i.mobilemenu-active
                color: #000!important
            .navbar-logo-text img
                height: 35px
                width: 35px
                object-fit: cover
                object-position: left
                margin-left: 10px
                position: relative
                top: -3px
                vertical-align: baseline
            .ant-switch-handle::before
                background: #000!important
            .darkModeToggle
                border-color: #000!important
            &.mobilemenu
                background-color: #ffffffdd!important
                background-size: 100%
                // backdrop-filter: blur(5px) saturate(130%)
                border-bottom: solid 2px v.$cyan-process
                .navbar-logo, i
                    color: #000!important
    .darkTheme
        .navbar
            // height: 50px
            .nav-links, svg, .navbar-logo
                color: #fff!important
            .nav-links.active
                color: #fff!important
            .ant-switch-handle::before
                background: #fff!important
            .darkModeToggle
                border-color: #fff!important
            &.mobilemenu
                background-size: 100%
                background-color: #000000dd!important
                // backdrop-filter: blur(5px) saturate(130%)
                border-bottom: solid 2px v.$cyan-process
            .mobile-menu-svg
                background-position: 50%
            .profile-menu
                .login-logout-menu
                    color: v.$font-darkmode
                    img
                        border: solid 2px v.$font-darkmode
                        box-sizing: content-box

    .nav-menu
        display: flex
        flex-direction: column
        flex: auto
        width: 100%
        height: 100vh
        position: absolute
        justify-content: flex-start
        padding-top: 55px
        top: -100vh
        left: 0
        bottom: 0
        opacity: 1
        transition: all 0.7s cubic-bezier(.77,0,.36,.98)
        overflow: hidden
        background: v.$colorbg-lightmode
        z-index: 1111
        &.activeMenu
            top: 0%
            grid-gap: 0
        .profile-dropdown
            display: none
        .profile-menu
            position: fixed
            bottom: 0%
            .ant-menu
                background: inherit
                border: none
            .loggedin-mobile-menu
                display: flex
                flex-direction: row
                justify-content: space-between
                height: 70px
                align-items: center
                padding: 0vw 5vw
                font-family: v.$font-content
                font-size: 16px
                font-weight: 600
                background: transparent
                .login-logout-menu
                    display: flex
                    gap: 10px
                    align-items: center

    .compact .nav-menu
        padding-top: 0

    // .lightTheme .nav-menu
    //     background: v.$colorbg-lightmode
    //     .nav-item 
    //         .nav-links
    //             color: #000

    .darkTheme .nav-menu
        background: v.$colorbg-darkmode!important
        // backdrop-filter: blur(10px)

    .compact
        .nav-menu.activeMenu
            left: 0
            opacity: 1
            z-index: 1
            grid-gap: 0
        .navbar-logo-text img
            top: -7.5px!important
    .nav-item
        .nav-links.active
            background: #242222
            background: v.$color-primary
            color: white!important
            left: 0
            opacity: 1
            z-index: 1
            color: white!important

        .nav-links
            text-align: center
            padding: 0.7rem
            width: 100%
            display: table
            font-size: 18px
            color: v.$font-lightmode
            border: none
        &.logout-link
            display: block
            background: v.$fire-opal
            .nav-links
                color: white!important

    .navbar-logo
        position: absolute
        top: 0
        left: 0
        transform: translate(20%, 30%)
        font-size: 1.4rem
        margin-left: 0px
        z-index: 111
        *
            background: transparent!important

    .menu-icon
        display: flex
        justify-content: center
        align-items: center
        position: absolute
        top: 0
        right: 0
        cursor: pointer
        height: 50px
        width: 75px
        text-align: center
        line-height: 55px
        transition: all 0.3s ease
        z-index: 111
        background: transparent

    .fa-bars, .fa-times
        font-size: larger
        background: transparent
    .compact .menu-icon
        height: 40px
        width: 50px
        line-height: 40px
        font-size: 0.7rem
        background: v.$color-primary

        &:hover
            background: v.$color-primary
    .compact .fa-bars, .compact .fa-times
        color: white
        font-size: medium
        background: transparent

    // .compact .nav-links
    //     padding: 1.2rem
    //     font-size: 18px
    .compact .nav-menu
        height: calc(100vh - 40px)

    .lightTheme
        .darkModeToggle
            .ant-switch-handle::before
                background: v.$colorbg-darkmode
            .ant-switch-inner
                color: v.$colorbg-darkmode

@media screen and (max-width: 768px)

    .photoGalleryTitle.scrolled
        font-size: 16px
        left: 18px!important
        padding: 5px 15px
    .nav-links-mobile
        display: block
    //     text-align: center
    //     margin: 2rem auto
    //     border-radius: 4px
    //     width: 80%
    //     text-decoration: none
    //     font-size: 1.5rem
    //     background-color: transparent
    //     color: #000
    //     padding: 14px 20px
    //     border: 1px solid #000
    //     transition: all ease 0.3s-out

    // .nav-links-mobile:hover
    //     background: #000
    //     color: #242424
    //     transition: 250ms
