@use './../../../assets/variables' as v

.myeditor-container
    padding: 470px 2vw 2vw 2vw
    margin: auto
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    .top-container
        height: 70vh
        width: 100%
        padding-top: 70px
        position: relative
        border-bottom: solid 5px #1abc9c
        background: white
        @media screen and (min-width: "1440px")
            height: 50vh
        .blog-container
            max-width: 1440px
            margin: auto

    .blog-image
        max-height: 200px
        margin: 2vw
    .btn.save-blog
        outline: none
        border: none
        color: white
        transition: all 0.3s ease
        font-family: v.$font-content
        width: max-content
        color: v.$font-lightmode
        background-color: #ccc
        opacity: 0.2
        &:not([disabled])
            opacity: 1
            background: v.$colorbg-darkmode
            color: white
        &:not([disabled]):hover
            background-color: #1abc9c!important
            -webkit-box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4)
            box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4)
            color: #fff!important
            -webkit-transform: translateY(-5px)
            -ms-transform: translateY(-5px)
            transform: translateY(-5px)
            opacity: 1
    .disclaimer-text
        position: absolute
        bottom: -1px
        right: 0px
        font-family: v.$font-content
        font-weight: 400
        color: #ffffffbb
        letter-spacing: 1px
        writing-mode: vertical-lr
        transform: rotate(180deg)
        background: v.$color-primary
        text-transform: uppercase
        padding: 18px 14px
    .img-container
        height: 100%
        max-width: 1400px
        width: 100%
        background-size: cover
        background-position: center -100px
        position: relative
        justify-content: space-around
        align-items: center
        display: flex
        flex-direction: row
        // background: linear-gradient(0deg, rgba(26,188,156,1) 0%, rgba(241,241,241,1) 80%)

        // background-color: #ffffff
        // background-image: url("data:image/svg+xml,%3Csvg width='48' height='64' viewBox='0 0 48 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 28v-4L36 12 24 24 12 12 0 24v4l4 4-4 4v4l12 12 12-12 12 12 12-12v-4l-4-4 4-4zM8 32l-6-6 10-10 10 10-6 6 6 6-10 10L2 38l6-6zm12 0l4-4 4 4-4 4-4-4zm12 0l-6-6 10-10 10 10-6 6 6 6-10 10-10-10 6-6zM0 16L10 6 4 0h4l4 4 4-4h4l-6 6 10 10L34 6l-6-6h4l4 4 4-4h4l-6 6 10 10v4L36 8 24 20 12 8 0 20v-4zm0 32l10 10-6 6h4l4-4 4 4h4l-6-6 10-10 10 10-6 6h4l4-4 4 4h4l-6-6 10-10v-4L36 56 24 44 12 56 0 44v4z' fill='%231abc9c' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E")
        &::after
            content: ""
            background: url("../../../assets/images/svg_post_dark.svg") right no-repeat
            background-size: 75%
            background-position: 50%
            width: 45%
            height: 100%
            display: block
            margin: auto
        .text-blog-headline, .type, .OR
            font-family: v.$font-headings
            font-size: 7vmax
            letter-spacing: -0.3vw
            font-weight: 600
            line-height: 0.75
            word-break: nowrap
            display: block
            margin: auto
                // text-shadow: 10px 10px 20px #0f0f0f, -10px -10px 20px #151515
            .asterisk
                color: v.$color-primary

        .img
            background: url("../../../assets/images/svg_thinking.svg") no-repeat
            background-size: 30%
            height: 100%
            background-position: 75% 102%

.editor-container
    max-width: 1440px
    margin: 4vmin
    display: flex
    flex-direction: column
    gap: 25px
    .editor-title, .editor-summary, .editor-content-title, .editor-image
        font-family: 'Roboto flex'
        font-weight: 600
        letter-spacing: -1px
        font-size: 24px

    .editor-content
        min-height: 200px
        padding: 20px
    .editor-image
        max-height: 200px

.editor-wrapper
    border: 1px solid #ccc
    padding: 10px
    background-color: #f9f9f9

.editor-toolbar
    border-bottom: 1px solid #ccc
    background-color: #f5f5f5
    padding: 5px

.editor-toolbar .rdw-option-wrapper
    padding: 15px
    border-radius: 5px
    button
        padding: 5px 10px
        background-color: #e6e6e6
        color: #333
        border: none
        border-radius: 3px
        margin: 2px
        transition: background-color 0.3s
        &:hover
            background-color: #ccc

.editor-toolbar .rdw-option-active
    background-color: #1abc9c
    img
        filter: invert(100%)

.darkTheme
    .login-page-container
        .loggedin-content
            .photos-category-creation-container
                .existing-categories-container
                    .existing-category-header
                        h1
                            color: v.$font-darkmode
                        .category-actions
                            svg
                                border: solid 2px v.$color-light-primary
                                color: v.$font-darkmode

                    .existing-categories .existing-category
                        box-shadow: none
                        border: solid 1px v.$color-dark-primary
                        .category-name
                            color: v.$font-darkmode
                            background: v.$color-dark-primary
                            bottom: -1px
            .blogs-container
                .blogs-title, .blog-link-title a
                    color: v.$font-darkmode
                .blog-link
                    background: #2f2f2f

    .myeditor-container .top-container
        background: v.$colorbg-darkmode
    .ant-upload button
        background: v.$color-container-darkmode
    input, .editor-wrapper, button
        background: v.$colorbg-darkmode
        border: solid 1px v.$color-dark-primary
        color: v.$font-darkmode
    .editor-toolbar
        background: v.$color-dark-primary
        border: v.$color-dark-primary
        .rdw-option-wrapper, .rdw-dropdown-wrapper
            color: black
            background: #8f8f8f
            border-color: v.$color-dark-primary
    .editor-title, .editor-summary, .editor-image, .editor-content-title
        color: v.$font-darkmode!important
    .btn.save-blog
        background: v.$colorbg-lightmode
        color: v.$font-lightmode

@media screen and ( min-width: 1400px )
    .myeditor-container
        .img-container
            .text-blog-headline, .type
                font-size: 5vmax!important
                letter-spacing: -0.2vw
                max-width: 45%

@media screen and ( max-width: 768px )
    .myeditor-container
        margin-top: 0
        .img-container
            flex-direction: column
            justify-content: space-evenly
            &::after
                height: 50%
                width: 60%
                background-size: 100%
                flex-wrap: wrap
