@use '../../../assets/_variables' as v

.cursor-pointer
    cursor: pointer

.blog-post-container
    padding-top: 70px
    .MuiSkeleton-root
        position: absolute
        width: 60%
        height: 500px
        border-radius: 10px
        top: 20px
    img.blog-header-image
        transition: all 1s ease
        height: 500px
        max-width: 100%
        border-radius: 12px
        margin: 0
        object-fit: contain
        object-position: left
    .blog-post
        max-width: 1400px
        margin: auto
        padding: 20px
        position: relative
        display: flex
        flex-direction: column
        width: 90%
        .blog-post-title
            font-family: v.$font-headings
            font-weight: 600
            letter-spacing: -1px
            font-size: 4vmax
            margin: 30px 0px 20px 0px
        .blog-post-content
            font-family: v.$font-content
            white-space: break-spaces
            word-wrap: break-word
            font-size: 16px
        .post-author-details
            display: flex
            flex-direction: row-reverse
            width: auto
            // border-top: solid 2px #dfdfdf
            align-items: center
            justify-content: flex-end
            margin-top: 4vh
            gap: 15px
            .post-author-texts
                display: flex
                flex-direction: column
                justify-content: center
                align-items: flex-start
                .username
                    color: #000
                    font-family: v.$font-headings
                    font-weight: 500
                    font-size: 20px
                    letter-spacing: -0.5px
                    white-space: break-spaces
                    // width: min-content 
                    line-height: 24px
                .post-time
                    color: #888
                    font-size: 14px
            .user-profile-picture
                display: flex
                height: 50px
                width: 50px
                img
                    object-fit: contain
                    border-radius: 50%
.comments-container
    margin-top: 30px
.comments-title
    font-family: v.$font-headings
    font-weight: 600
    letter-spacing: 1px
    padding: 0
.comments
    display: flex
    flex-wrap: wrap
    font-family: v.$font-headings
    margin-top: 20px
    gap: 20px
    .ant-comment
        padding: 0px 1rem
        background: #fff
        border-radius: 20px
        width: max-content
        &-inner
            align-items: baseline
        // box-shadow: -3px 3px 2px 0px v.$color-primary
        &-content-detail
            font-family: v.$font-content
            margin-bottom: 10px
            margin-right: 10px
        .delete-comment
            display: block
            height: 28px
            width: 28px
            position: absolute
            right: 10px
            top: 10px
            padding: 7px
            border-radius: 50%
            transition: all 0.3s ease
            &:hover
                background: v.$fire-opal!important
                color: #fff
.new-comment
    margin-top: 20px
    border-top: solid 0.5px #ccc
    padding-top: 20px
    &.login-required
        font-family: v.$font-content
        font-size: 18px
        .login-link
            cursor: pointer
            color: white
            font-weight: 800
            padding: 2px 10px
            margin: 0px 3px
            background: v.$fire-opal
            border-radius: 10px
            font-size: 20px
            text-transform: capitalize
            transition: all 0.3s ease
            &:hover
                background: v.$cyan-process
.submitbtn
    background: #1890ff!important
    span
        background: transparent!important
        color: white

.ant-comment-content-author
    margin-right: 20px
    &-name
        font-size: 16px!important
        margin-bottom: 5px
        font-weight: 500
        font-family: v.$font-headings
        *
            color: black!important
.ant-comment-content-author-time
    font-size: 12px!important
    font-family: v.$font-content
    font-weight: 500
    color: #999!important

.darkTheme
    .blog-post-container
        .blog-post
            .blog-post-title, .ant-comment-content-author-name *, .post-author-texts .username
                color: v.$font-darkmode!important
            .comments-container
                .comments-title
                    color: v.$font-darkmode!important
                .new-comment
                    .ant-input
                        background: v.$color-container-darkmode!important
                        border: none
                        color: v.$font-darkmode
                .comments .ant-comment
                    background: v.$color-container-darkmode

.snack-bar-message
    .ant-message
        &-notice-content
            position: fixed
            bottom: 100px
            right: 100px
            display: flex
            align-items: center
            justify-content: center
            color: white
            font-family: Manrope
            font-size: 16px
            border-radius: 10px
            overflow: hidden
            padding: 0
            width: max-content
            @media screen and (max-width: 760px)
                right: 50%
                transform: translateX(50%)
        &-custom-content
            display: flex
            border-radius: 10px
            align-items: center
            padding: 0.7rem 1em
            gap: 5px
            .anticon
                font-size: 18px
                color: white
        &-success
            background: v.$color-primary
        &-error
            background: v.$fire-opal

@media screen and (max-width: 768px)
    .blog-post-container
        .MuiSkeleton-root
            height: 40vh
            width: calc(100% - 40px)
        img.blog-header-image
            width: 100%
            max-height: 40vh
