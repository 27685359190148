@use './../../../assets/variables' as v

@mixin div-same-size-as-background-img($url)
    background-image: url($url)
    background-size: contain
    background-repeat: no-repeat
    width: 100%
    height: 0
    padding-top: percentage(image-height($url) / image-width($url))
.card-container
    position: relative
    overflow: hidden
    // min-width: 30vw
    // box-shadow: -10px 10px 10px 0px #444
    transition: all 1s ease
    // max-width: 320px
    max-height: 500px

    // opacity: 0
    //     box-shadow: -15px 15px 15px 0px #888
.card
    // @include div-same-size-as-background-img()
    height: 100%
    width: 100%
    border-radius: 0px
    border: none
    position: relative
    cursor: pointer
    transition: 0.5s ease
    background-position: center center
    transition: all 0.3s ease
    &:hover
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px
        transform: scale(1.1)
    &.skeleton:hover
        transform: none

.card-container > .photoType
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    font-family: 'ROBOTO SLAB'
    font-size: 30px
    padding: 0px 15px
    background: rgba(255, 255, 255, 0.8)
    opacity: 0
    transition: 0.5s ease
    cursor: pointer

.hoverEnabled:hover .card
    transform: scale(1.2)

.card-container:hover > .photoType
    color: #000
    display: inline
    opacity: 1
    transition: 0.5s ease

.hidden
    opacity: 0
    transition: all 0.2s ease

.visibleNow
    opacity: 1
    transition: all 0.2s ease

@media screen and ( max-width: 960px )
    .card-container
        // max-height: 90vh!important
        max-width: 90vw!important

.darkTheme
    .imagewrapper
        .imagewrapper
            border: solid 1px v.$color-dark-primary

.imagewrapper
    width: -moz-fit-content
    width: fit-content
    max-width: 100%
    max-height: 500px
    height: auto
    margin: 0 auto
    position: relative
    user-select: none
    border-radius: 10px
    overflow: hidden
    &.edit-mode 
        &.inActive
            opacity: 0.7
        img
            max-height: 280px
            @media screen and (max-width: 768px)
                max-height: unset
    &:hover
        .likes-container
            opacity: 1
            // width: 120px
        .likes-container .like-count
            opacity: 1
    .likes-container
        position: absolute
        bottom: 10px
        right: 10px
        display: flex
        align-items: center
        color: white
        font-family: v.$font-content
        padding: 5px 10px
        background: #ffffff77
        backdrop-filter: blur(10px)
        color: black
        border-radius: 10px
        justify-content: flex-end
        gap: 10px
        width: 45px
        transition: all 0.3s cubic-bezier(.77,0,.36,.98)
        .like-count
            overflow: hidden
            white-space: nowrap
            font-size: 18px
            opacity: 0
            transition: all 0.3s cubic-bezier(.77,0,.36,.98)
        .like-photo-icon
            font-size: 24px
            width: min-content
            transition: all 0.3s cubic-bezier(.77,0,.36,.98)
            cursor: pointer
            &.fill
                color: v.$fire-opal
                font-size: 24px
            &.heartBeat
                animation: heartBeat 1s normal
    img
        width: 100%
        height: 100%
        object-fit: cover
        max-width: 100%
        height: 500px
        transition: all 1s ease
        opacity: 0
        @media screen and (max-width: 768px)
            height: auto 

@keyframes heartBeat
    0%
        transform: scale(1)

    14%
        transform: scale(1.3)

    28%
        transform: scale(1)

    42%
        transform: scale(1.3)

    70%
        transform: scale(1)
